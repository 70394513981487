/* AddGameForm.css */
.add-game-form-dialog {
    background-color: #333;
    color: #ffffff;
}

.add-game-form-textfield input {
    color: #ffffff;
}

.add-game-form-textfield label {
    color: #ffffff;
}