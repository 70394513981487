/* Fotter.css */
.footer-container {
    background-color: #333;
    color: white;
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}