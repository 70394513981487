/* GameList.css */
.add-game-button {
    color: #ffffff;
    background-color: #333;
    margin-bottom: 20px;
}

.game-card {
    margin: 20px 0;
    background-color: #333 !important;
    color: #E0E0E0;
}

.game-card-media {
    filter: brightness(0.8);
}

.game-title {
    color: #ffffff;
}

.game-description,
.game-genre,
.game-multiplayer {
    color: #E0E0E0;
}

.card-actions {
    display: flex;
    justify-content: flex-end;
}

.edit-button,
.delete-button {
    color: #ffffff;
}

.edit-button:hover,
.delete-button:hover {
    color: #ff9800;
}