/* HomePage.css */
/* ホームページコンテナのスタイル */
.homepage-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 100px;
    padding: 4em 0;
    /* タイトルテキストの高さをAboutPageと揃える */
}

/* テキストのスタイル */
.hero-title {
    color: var(--secondary-color);
    font-size: 3rem;
    font-weight: bold;
    background: transparent;
}

.hero-subtitle {
    color: var(--secondary-color);
    font-size: 1.5rem;
}

/* ナビゲーションボタンのスタイル */
.nav-buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 30px;
    width: 100%;
    justify-items: center;
}

.custom-button {
    background-color: var(--accent-color);
    color: var(--secondary-color);
    transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
    padding: 15px 30px;
    font-size: 18px;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    border: 1px solid #007acc;
    /* クールな色合いの枠線 */
}

.custom-button:hover {
    transform: scale(1.05);
    background-color: var(--hover-color);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    border-color: #00bfff;
    /* 枠線の色を変更 */
}

@media (max-width: 768px) {
    .nav-buttons {
        grid-template-columns: 1fr;
    }
}

.nav-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}