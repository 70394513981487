/* Header.css */
.drawer-list {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    padding: 20px;
    list-style: none;
}

.drawer-link {
    color: var(--secondary-color);
    text-decoration: none;
    display: block;
    padding: 10px 0;
    transition: color 0.3s ease;
}

.drawer-link:hover {
    color: var(--accent-color);
}