/* EventForm.css */
.event-form-dialog {
    background-color: #333;
    color: #ffffff;
}

.event-form-textfield input {
    color: #ffffff;
}

.event-form-textfield label {
    color: #ffffff;
}