/* AdminPage.css */
.user-avatar {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    margin-right: 16px;
}

.user-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    margin: 8px 0;
    background-color: #282828;
    border-radius: 8px;
    transition: background-color 0.3s ease;
}

.user-list-item:hover {
    background-color: #333;
}

.approve-button {
    color: #4caf50;
}

.reject-button {
    color: #f44336;
}