/* AttendanceCheck.css */
.attendance-check-description {
    color: #ffffff;
}

.attendance-check-status {
    color: #aaaaaa;
}

.attendance-check-button {
    margin-right: 10px;
}

.attendance-check-list {
    color: #ffffff;
}