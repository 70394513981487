/* index.css */
:root {
  --base-color: #0a0a0a;
  --accent-color: #005cc5;
  --hover-color: #45a049;
  --primary-color: #333;
  --secondary-color: #ffffff;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--base-color);
  color: var(--secondary-color);
  margin: 0;
}

header,
footer {
  background: var(--primary-color);
  color: var(--secondary-color);
  box-shadow: 0 2px 4px var(--base-color);
  position: fixed;
  width: 100%;
  z-index: 1000;
}

footer {
  bottom: 0;
}

nav ul {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

nav a {
  color: var(--secondary-color);
  text-decoration: none;
  padding: 0 15px;
  transition: color 0.3s ease;
}

nav a:hover {
  color: var(--accent-color);
}

main {
  padding-top: 80px;
  padding-bottom: 80px;
}

button {
  color: var(--secondary-color);
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
}