/* GameCalendar.css */
.game-calendar-container {
    padding: 20px;
    background-color: #1c1c1c;
    color: #E0E0E0;
    border-radius: 8px;
}

.custom-button {
    background-color: var(--hover-color);
    color: var(--secondary-color);
    margin-bottom: 20px;
    transition: transform 0.3s ease;
}

.custom-button:hover {
    transform: scale(1.1);
    background-color: var(--hover-color);
    color: var(--secondary-color);
}

.rbc-event {
    transition: transform 0.2s, background-color 0.2s;
}

.rbc-event:hover {
    transform: scale(1.05);
    background-color: #005cc5 !important;
}

.rbc-today {
    background-color: #1c1c1c !important;
}

.rbc-off-range-bg {
    background-color: #282828 !important;
}

.rbc-month-view,
.rbc-time-view,
.rbc-agenda-view {
    background-color: #121212;
    color: #E0E0E0;
}

.rbc-toolbar {
    background-color: #333;
    color: white;
}

.rbc-toolbar button {
    color: white;
    background-color: #005cc5;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    margin: 0 2px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.rbc-toolbar button:hover {
    background-color: #0047a3;
}

.rbc-event-label {
    display: none;
}