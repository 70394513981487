/* Auth.css */
.auth-container {
    display: flex;
    justify-content: center;
}

.auth-form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.auth-button {
    width: 100%;
}