/* MyPage.css */
.my-page-container {
    text-align: center;
    margin-top: 1rem;
    padding: 1.5rem;
    background-color: #1c1c1c;
    color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.my-page-textfield input {
    color: #ffffff;
}

.my-page-textfield label {
    color: #ffffff;
}

.my-page-button {
    margin-top: 1rem;
}

.my-page-google-button {
    margin-top: 1rem;
}